import React from 'react'
import logo from '../assets/img/logo.png'
import appleIcon from '../assets/img/icons/apple.svg'
import playstoreIcon from '../assets/img/icons/playstore.svg'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <div className='faqra-club-section'>
            <header>
                <Link to='/'><img src={logo} alt='logo-image' className='img-fluid' /></Link>
            </header>

            <section>
                <div className='faqra-club-section-content'>
                    <h1>Under Renovation</h1>
                    <div className='download-icons'>
                        <Link to='https://apps.apple.com/ae/app/faqra-club/id1525512860' target="_blank"><img src={appleIcon} alt='apple-icon' className='img-fluid' /></Link>
                        <Link to='https://play.google.com/store/apps/details?id=com.nova4.faqra&pcampaignid=web_share' target="_blank"><img src={playstoreIcon} alt='playstore-icon' className='img-fluid' /></Link>
                        <span>Download the app</span>
                    </div>
                </div>
            </section>

            <footer>
                <div className='container'>
                    <div className='contact-section'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className='d-flex footer-part-1'>
                                    <div className='contect-icons d-flex '>
                                        <Link to='tel:+9613574888' target="_blank">
                                            <div className='icon'>
                                                <i className="fa-solid fa-phone"></i>
                                            </div>
                                        </Link>
                                        <Link to='https://wa.me/9613574888' target="_blank">
                                            <div className='icon'>
                                                <i className="fa-brands fa-whatsapp wp-icon"></i>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='contect-number'>
                                        <p className='ms-2'>
                                            <Link to='tel:+9613574888' target="_blank">+961 3 574 888</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className='d-flex footer-part-2'>
                                    <div className='stay-connected'>
                                        <p className='me-3'>Stay Connected</p>
                                    </div>
                                    <div className='social-icons d-flex'>
                                        <Link to='https://www.facebook.com/faqraclublb/' target="_blank">
                                        <div className='icon'>
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </div>
                                        </Link>
                                        <Link to='https://www.instagram.com/faqraclub/' target="_blank">
                                        <div className='icon'>
                                            <i className="fa-brands fa-instagram"></i>
                                        </div>
                                        </Link>
                                       <Link to='https://www.linkedin.com/company/faqra-club-by-solipro' target="_blank">
                                       <div className='icon'>
                                            <i className="fa-brands fa-linkedin-in"></i>
                                        </div>
                                       </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Home
